import React from 'react'
import './Service.css'
import ServicesItems from './ServicesItems'
import electromagnetiqueImg from 'assets/img/electro2.jfif'
import dataengImg from 'assets/img/dataeng.jpg'
import engImg from 'assets/img/eng.jpg'
import webImg from 'assets/img/web.jpg'
import { MDBAnimation } from "mdbreact";
let nosService=[
  {
    title:"SI Business  & Web ",
    description: <div >Conception et évolution des systèmes d’information des grandes entreprises et des PME
    <ul class="ul">
   {/* <li  class="li" > Agilité</li> */}
   <li  class="li"> Gestion de projets</li>
   <li  class="li"> Architectes certifiés sur les infrastructure Cloud</li>
   <li  class="li"> Partenariat stratégique</li>
  
  </ul></div> ,
    image:webImg
  },

  {
    title:"System Ingeneering ",
    description:<div >Apporter une valeur ajoutée grâce aux systèmes intelligents
    <ul class="ul">
   <li  class="li" > Créer des produits intelligents & innovants</li>
   <li  class="li">  Gérer et contrôler la conception des systèmes</li>
   {/* <li  class="li"> Analyser et gérer la complexité et les risques</li> */}
   <li class="li" > Sécuristaion des systèmes informatiques </li>
  </ul></div>,
    image:engImg
  },
  {
    title:"Data Intelligence ",
    description:  <div > Analyses ou valorisations de vos données pour tirer profit
     <ul class="ul">
    <li class="li" > Cloud Management des données</li>
    <li class="li" > Modélisation de données </li>
    <li class="li" > Optimisation du stockage </li>
  </ul></div>,
    image:dataengImg
  },

  {
    title:"Expertise Electromagnétique ",
    description:<div >Etude sur l'exposition des personnes aux ondes électromagnétique
     <ul class="ul">
    <li class="li" > Homologation d’équipement</li>
    <li class="li" > Contrôle de l’exposition aux ondes électromagnétiques</li>
    <li class="li" > Simulation électromagnétique</li>
  </ul></div> ,
    image:electromagnetiqueImg
  },

];

export default function Service() {
      
    return (
      <MDBAnimation reveal type="fadeInRight" duration="2s">


        <section id="service" className="services-mf route">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">
                  Nos Services
                </h3>
                {/* <p className="subtitle-a">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </p> */}
                <div className="line-mf" />
              </div>
            </div>
          </div>
          <div className="row">
          { nosService.map((item,i )=>{
            return (
              <ServicesItems
          title={item.title}
          description={item.description}
          image={item.image}
          key={i}
          
          />

            )
          }) 
          }
             
          </div>
        </div>
      </section>
      </MDBAnimation>
    )
}
