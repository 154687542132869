import Home from 'component/Home'

function App() {
  return (
    <div className="App">
     <Home/>
    </div>
  );
}

export default App;
