import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { MDBAnimation } from "mdbreact";



import {Crypt} from 'hybrid-crypto-js';


const apiSendContact = function (data){
  return fetch(process.env.REACT_APP_AWS_URL_BACKEND_CONTACT, {
      method: 'POST',
      headers: {
          'Content-Type': "application/json",
          "x-api-key": process.env.REACT_APP_AWS_API_X_KEY_API
      },
      body: JSON.stringify(data)
  })
}
//le Regex pour controller la saisie du numero de téléphone
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object().shape({
  token: yup.string(),
  name: yup.string().min(4, 'Votre nom doit faire 4 caractères minimum.').required('Vous devez rentrer votre nom.'),
  telephone: yup.string().matches(phoneRegExp, 'Votre numero doit être valide').required('Vous devez rentrer votre numero de tél pour vous recontactez.'),
  email: yup.string().email('Votre email doit être valide.').required('Email est requis'),
  objet: yup.string().min(3, 'L\'objet du message doit faire 4 caractères minimum').required('Vous devez rentrer l\'objet de votre message.'),
  message: yup.string().min(14, 'Le message doit faire 15 caractères minimum').required('Vous devez saisir un dans votre message.')
})

export default function Footer(props) {




  // const { idLink } = props;
    var crypt = new Crypt();
    var message = 'donnisirapass';
    
    const { register, handleSubmit, formState, errors, reset , setError} = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });
    const { isSubmitting, isSubmitSuccessful } = formState;

    const onSubmit = async data => {
        //await wait(2000);
        const response = apiSendContact(data);
        
        await response.then( response => {
            //const data = response.json();
            reset();
        }).catch(error => {
            if(error.message==="KO"){
                error.errors.forEach((x)=>{
                    setError(x.id, x.error);
                })
            }
        });
    };
    
    const publicKey = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQD88Wo31gsoMAQdjs0XUfRAtbZx
    XcgR7JYvVFGY9OXsMfF/KvKf/kCETyC/fgMS4oGNxbFSricOYO3IbAxrq419kA5u
    ewlm+dCU0c+jdPzGCInSFzJXDlkd6QU4an4MfyPBRjubrBi15VycNg10geXTAK/R
    rEQL0CAj7BfarKNCBwIDAQAB
    -----END PUBLIC KEY-----
    `;
    const token = crypt.encrypt(publicKey, message);





    return (
        <section  className="paralax-mf footer-paralax bg-image sect-mt4 route" style={{backgroundImage: 'url(img/overlay-bg.jpg)'}}>
            <div className="overlay-mf" />
            <MDBAnimation reveal type="fadeInUpBig" duration="2s">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="contact-mf">
                    <div id="contact" className="box-shadow-full">
                      <div className="row">
                      <input type="hidden" id="token" name="token" value={token}
                                                ref={register}
                                    />
                        <div className="col-md-6">
                          <div className="title-box-2">
                            <h5 className="title-left">
                            Envoyez-nous un message
                            </h5>
                          </div>
                          <div>
                            <form action method="post"  onSubmit={handleSubmit(onSubmit)}>
                              <div > {isSubmitSuccessful && <div className="alert alert-success" role="alert">
                            <strong>Votre message a bien été envoyé.</strong> <br />
                            Nous reviendrons vers vous dans les plus bref délai, Merci.
                        </div>}</div>
                              <div id="errormessage" />
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <input type="text" name="name" className="form-control" id="name"
                                     placeholder="votre nom" 
                                     data-rule="minlen:4" data-msg="Veuillez saisir au moins 4 caractères"
                                      ref={register}
                                      disabled={isSubmitting}/>
                                      <div className="validation" />

                                        {errors.name && <div className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.name.message}</div>}
                                    
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <input type="tel" className="form-control"
                                     name="telephone" id="telephone" 
                                     placeholder="Téléphone"
                                     ref={register}
                                      disabled={isSubmitting}
                                      />
                                      <div className="validation" />

                                    {errors.telephone && <div className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.telephone.message}</div>}
                                    
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <input type="email" 
                                    className="form-control" name="email" 
                                    id="email" placeholder="Votre e-mail"
                                    ref={register}
                                    data-rule="email" data-msg="Veuillez saisir une adresse e-mail valide" 
                                    // disabled={isSubmitting}
                                />
                                <div className="validation" />
                                {errors.email && <div className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.email.message}</div>}

                                    
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <input type="text"
                                     className="form-control" name="objet" id="msg_subject" 
                                     placeholder="Sujet" 
                                     data-rule="minlen:4" data-msg="Veuillez saisir au moins 8 caractères de sujet" 
                                     ref={register}
                                    disabled={isSubmitting}
                                    />
                                    <div className="validation" />
                                    {errors.objet && <div className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.objet.message}</div>}

                                    
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <textarea className="form-control" name="message" rows={5} 
                                    data-rule="required" data-msg="S'il vous plaît écrivez quelque chose pour nous"
                                     placeholder="Message" id="message" defaultValue={""} ref={register}
                                     disabled={isSubmitting}
                                 ></textarea>
                                 <div className="validation" />
                                 {errors.message && <div className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.message.message}</div>}

                                    
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <button type="submit"
                                   className="button button-a button-big button-rouded"
                                   disabled={isSubmitting}
                                   >Envoyer le message</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title-box-2 pt-4 pt-md-0">
                            <h5 className="title-left">
                            Entrer en contact
                            </h5>
                          </div>
                          <div className="more-info">
                            {/* <p className="lead">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis dolorum dolorem soluta quidem
                              expedita aperiam aliquid at.
                              Totam magni ipsum suscipit amet? Autem nemo esse laboriosam ratione nobis
                              mollitia inventore?
                            </p> */}
                            <ul className="list-ico">
                              <li><span className="ion-ios-location" /> La Société « EEETECH », 
                              Société à responsabilité limitée dont le siège social se situe à Faladiè
                              rue 838 porte 89 Bamako,
                              </li>
                              <li><span className="ion-ios-telephone" /> +223 83 14 70 11</li>
                              <li><span className="ion-email" /> contact@eeetech.net</li>
                            </ul>
                          </div>
                          <div className="socials">
                            <ul>
                              {/* <li><a href="/"><span className="ico-circle"><i className="ion-social-facebook" /></span></a></li> */}
                              {/* <li><a href="/"><span className="ico-circle"><i className="ion-social-instagram" /></span></a></li> */}
                              {/* <li><a href="/"><span className="ico-circle"><i className="ion-social-twitter" /></span></a></li> */}
                              {/* <li><a href="/"><span className="ico-circle"><i className="ion-social-pinterest" /></span></a></li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </MDBAnimation>
            <footer>
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="copyright-box">
                    <p>Copyright © 2021 <a rel="nofollow" href="https://eeetech.net">3Etech</a> Tous droits réservés</p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </section>
    )
}
