import React from 'react'
import Header from 'component/Header/Header'
import About from 'component/About'
import Service from 'component/NosServices/Service'
//import Portofolio from '../component/Portofolio'
//import Blog from '../component/Blog'
import Footer from 'component/Footer'
//import { MDBAnimation } from "mdbreact";
// import testimonial2 from '../img/testimonial-2.jpg'
// import testimonial4 from '../img/testimonial-4.jpg'
import introBg from 'assets/img/intro-bg-2.jpeg'

export default function Home() {

  let url = "#"
    return (
        <>

          {/*/ Nav Star /*/}
          <Header/>
          {/*/ Nav End /*/}
         

          {/*/ Intro Skew Star /*/}
          <div id="home" className="intro route bg-image" style={{backgroundImage: 'url('+introBg+')'}}>
            <div className="overlay-itro" />
            <div className="intro-content display-table">
              <div className="table-cell">
                <div className="container">
                  {/*<p class="display-6 color-d">Hello, world!</p>*/}
                  <h1 className="intro-title mb-4">3ETECH</h1>
                  <p className="intro-subtitle mb-4">Let's reinvent the future</p>
                  <p className="intro-subtitle">Leader <span className="text-slider-items"> de la transformation digitale, des études électromagnétiques, de l'optimisation des réseaux télécom, du développement d’applications.</span><strong className="text-slider" /></p>
                  {/* <p class="pt-3"><a class="btn btn-primary btn js-scroll px-4" href="#about" role="button">Learn More</a></p> */}
                </div>
              </div>
            </div>
          </div>
         
          {/*/ Intro Skew End /*/}
          <div className='p-80'>
          <About/>
          </div>
         
          {/*/ Section Services Star /*/}
             <Service/>
          {/*/ Section Services End /*/}
          {/* <MDBAnimation reveal type="fadeInLeft" duration="2s">
          <div className="section-counter paralax-mf bg-image" style={{backgroundImage: 'url(img/counters-bg.jpg)'}}>
            <div className="overlay-mf" />
            <div className="container">
              <div className="row">
                <div className="col-sm-3 col-lg-3">
                  <div className="counter-box">
                    <div className="counter-ico">
                      <span className="ico-circle"><i className="ion-checkmark-round" /></span>
                    </div>
                    <div className="counter-num">
                      <p className="counter">450</p>
                      <span className="counter-text">TRAVAUX TERMINÉS</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-lg-3">
                  <div className="counter-box pt-4 pt-md-0">
                    <div className="counter-ico">
                      <span className="ico-circle"><i className="ion-ios-calendar-outline" /></span>
                    </div>
                    <div className="counter-num">
                      <p className="counter">15</p>
                      <span className="counter-text">DES ANNÉES D'EXPÉRIENCE</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-lg-3">
                  <div className="counter-box pt-4 pt-md-0">
                    <div className="counter-ico">
                      <span className="ico-circle"><i className="ion-ios-people" /></span>
                    </div>
                    <div className="counter-num">
                      <p className="counter">550</p>
                      <span className="counter-text">TOTAL CLIENTS</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-lg-3">
                  <div className="counter-box pt-4 pt-md-0">
                    <div className="counter-ico">
                      <span className="ico-circle"><i className="ion-ribbon-a" /></span>
                    </div>
                    <div className="counter-num">
                      <p className="counter">36</p>
                      <span className="counter-text">PRIX GAGNÉ</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </MDBAnimation> */}
         <Footer/>
          {/*/ Section Contact-footer End /*/}
          <a href={url} className="back-to-top"><i className="fa fa-chevron-up" /></a>
          <div id="preloader" />
          {/* JavaScript Libraries */}
          {/* Contact Form JavaScript File */}
          {/* Template Main Javascript File */}
        </>
      );
}

  