import React from 'react'
import logoeeetech from 'assets/img/LogoEeetech3.png'
import './Header.css'
export default function Header() {
 
    return (
       <>
            <nav className="navbar navbar-b navbar-trans navbar-expand-md fixed-top" id="mainNav">
            <div className="container">
              <a className="navbar-brand" href="#page-top"> < img className="logoimg" src={logoeeetech} alt="logo" /></a>
              <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span />
                <span />
                <span />
              </button>
              <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link js-scroll active" href="#home">Accueil </a>
                    {/* <Link to='#home' className="nav-link js-scroll active" >Accueil </Link> */}
                  </li>
                  <li className="nav-item">
                    <a className="nav-link js-scroll" href="#about">A propos</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link js-scroll" href="#service">Nos services</a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link js-scroll" href="#work">Portofolio</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link js-scroll" href="#blog">Blog</a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link js-scroll" href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
       </>
    )
}
