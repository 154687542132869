import './Service.css'
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'


export default function ServicesItems(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
     
      <Modal show={show} onHide={handleClose} {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title ><span style={{textAlign:"center"}}> {props.title}</span>
          <img className="ico-image" src={props.image} width={150} height={300} alt={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.description}</Modal.Body>
        <Modal.Footer>
          <button className="button button-a button-rouded" onClick={handleClose}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
      <div className="col-md-3" onClick={handleShow} style={{cursor:"pointer"}}>
            <div className="service-box">
              <div className="service-ico">
                  <img className="ico-image" src={props.image} width={100} alt={props.title} />
                {/* <span className="ico-circle"><i className={props.icon} /></span> */}
                {/* <span className="ico-circle"><img src={image} width={100} /></span> */}
              </div>
              <div className="service-content">
                <h2 className="s-title">{props.title}</h2>
                <p className="s-description">
                {props.description}
                </p>
              </div>
            </div>
          
          </div>
          </>    
     
  )
}
