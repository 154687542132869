import React from 'react'
//import introBg from 'assets/img/intro-bg-2.jpeg'
import { MDBAnimation } from "mdbreact";
//import testimonial2 from 'assets/img/testimonial-2.jpg'
//import abouteeetecImage from 'assets/img/eeetech_activity.svg';
export default function About() {
    return (
      <MDBAnimation reveal type="fadeInLeft" duration="2s">
            
                     
   <section id="about" className="about" style={{paddingTop: 20}}>
      <div className="container" data-aos="fade-up">


        <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">
                À propos de nous
                </h3>
                {/* <p className="subtitle-a">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </p> */}
                <div className="line-mf" />
              </div>
            </div>
          </div>
        <div className="row gy-4" data-aos="fade-up">
          <div className="col-lg-4">
            <img src="assets/img/about-us.png" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-8">
            <div className="content ps-lg-5 " style={{ textAlign: 'justify'}}>
              {/* <h3>Présentation du cabinet</h3> */}
           
                <p >
                EEETECH
                    est une entreprise de conseil et d’expertise créée par trois Ingénieurs. </p>
                <p>
                Nous sommes spécialisés dans les systèmes d'ingéneries,
                les réseaux télécom,
                les systèmes électroniques/gestion de projet et les architectures de solution IT,
                les environnements électromagnétiques, la digitalisation et le Big-Data. </p> 
              <p >
              Nous accompagnons nos clients et les acteurs 
                 du numérique sur chaque phase du cycle de vie de leur projet Technologique. </p>
                <p>
                Nous apportons à nos clients des solutions dédiées, 
                flexibles et pro-actives.
                Permettre aux entreprises de réduire leur coût de production 
                et de fonctionnement. Grâce au digital 
                de fiabiliser vos procédures et processus. </p>
             
              
            </div>
          </div>
        </div>
      </div>
    </section>
            
            
            
             {/* <section id="about" className="about-mf sect route">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="box-shadow-full route bg-image myimage"  style={{backgroundImage: 'url('+introBg+')'}}>
                    <div className="row">

                      <div className="col-md-6 s-description2" >
                        <div className="about-me pt-4 pt-md-0" >
                          <div className="title-box-2">
                            <h5 className="title-left " style={{color:'white'}}>
                              À propos de nous
                            </h5>
                          </div>
                          <p className="s-description2">
                          EEETECH
                          est une entreprise de conseil et d’expertise créée par trois Ingénieurs. 

                          </p>
                          <p className="s-description2">
                          Nous sommes spécialisés dans les systèmes d'ingéneries,
                          les réseaux télécom,
                          les systèmes électroniques/gestion de projet et les architectures de solution IT,
                          les environnements électromagnétiques, la digitalisation et le Big-Data.
                          </p>
                          <p className="s-description2">
                          Nous accompagnons nos clients et les acteurs 
                          du numérique sur chaque phase du cycle de vie de leur projet Technologique.
                          </p>

                          <p className="s-description2">
                          Nous apportons à nos clients des solutions dédiées, 
                          flexibles et pro-actives.
                          Permettre aux entreprises de réduire leur coût de production 
                          et de fonctionnement. Grâce au digital 
                          de fiabiliser vos procédures et processus.
                          </p>

                        

                        </div>
                      </div>
                      <div className="col-md-6" style={{alignSelf: "center"}}>
                        
                        
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          </MDBAnimation>
    )
}
